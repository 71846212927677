<template>
  <v-card height="200px" flat>
    <v-card-title><span class="text-h6">{{cardText}}</span></v-card-title>
    <v-card-text class="pa-0">
      <v-container fluid pa-0>
        <!-- <v-row no-gutters class="justify-space-between px-4">
          <v-col class="text-start"><span>{{app_types[type]}}</span></v-col>
          <v-col class="text-end"><span>0</span></v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col cols="3">
             <!-- class="cursor-pointer" @click="filterTable('guest')"> -->
            <v-container fluid pa-0 text-center>
              <v-row no-gutters >
                <v-col cols="12">
                  <span class="text-h2" >{{guestCount}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Guest</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="3">
             <!-- class="cursor-pointer" @click="filterTable('monthly')"> -->
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{monthlyCount}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Monthly</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="3">
             <!-- class="cursor-pointer" @click="filterTable('transient')"> -->
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{transientCount}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Transient</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="3">
             <!-- class="cursor-pointer" @click="filterTable('vip')"> -->
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{vipCount}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">VIP</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <loading-overlay :loading="loading"></loading-overlay> -->
  </v-card>
</template>

<script>
// import DoughNut from "@/lib/Doughnut.js";
// import { EventBus } from "@/lib/EventBus";
export default {
  name: "AppUsageOverview",
  components: {},
  props: {
    events_data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    cardText: {
      type: String,
      default: function () {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    }
  },
  data() {
    return {
      guestCount:0,
      monthlyCount:0,
      transientCount:0,
      vipCount:0
    };
  },
  computed: {},
  watch: {
    events_data() {
      this.getTypeCount();
    },
  },
  created() {},
  methods: {
    // filterTable(type){
    //   EventBus.$emit("filter-live-table", type);
    // },
    getTypeCount() {
      let events = this.events_data;
      this.guestCount =
        events.length > 0
          ? events.filter((ev) => ev.TypeOfBooking === "Guest").length
          : 0;
      this.monthlyCount =
        events.length > 0
          ? events.filter((ev) => ev.TypeOfBooking === "Monthly").length
          : 0;
      this.vipCount =
        events.length > 0
          ? events.filter((ev) => ev.TypeOfBooking === "VIP").length
          : 0;
      this.transientCount =
        events.length > 0
          ? events.filter((ev) => ev.TypeOfBooking === "Transient" || ev.TypeOfBooking == "Sphere App").length
          : 0;
    },
  },
};
</script>
