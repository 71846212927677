<template>
  <v-card flat>
    <v-card-title>
      <v-container fluid pa-0>
        <v-row no-gutters>
          <v-col class="align-self-center">
            <span>Live Events</span>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              hide-details
              outlined
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="computedHeaders"
        :items="events_data"
        :search="search"
        class="pa-2"
      >
        <template v-slot:[`item.orderID`]="{ item }">
          {{ item.orderID == "" ? "-" : item.orderID }}
        </template>
        <template v-slot:[`item.vehicleNo`]="{ item }">
          {{ item.vehicleNo == "" ? "-" : item.vehicleNo }}
        </template>
        <template v-slot:[`item.zcode`]="{ item }">
          {{
            item.zcode == "" ? "-" : item.zcode + " ( " + item.zoneName + " ) "
          }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          {{ item.email == "" ? "-" : item.email }}
        </template>
        <template v-slot:[`item.forceCheckOut_by`]="{ item }">
          {{ item.forceCheckOut_by == "" ? "-" : item.forceCheckOut_by }}
        </template>
        <template v-slot:[`item.TypeOfBooking`]="{ item }">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col>
                <v-chip :color="getColor(item.TypeOfBooking)" label small>
                  {{ item.TypeOfBooking }}
                </v-chip>
              </v-col>
              <v-col cols="2">
                <v-icon
                  class="ml-1"
                  color="primary"
                  small
                  @click="openInfoDialog(item)"
                >
                  info</v-icon
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:[`item.status_text`]="{ item }">
          <span v-if="item.status == 2 || item.status == 4 || item.status == 11"
            >{{ item.status_text
            }}<v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="openForceCheckOutDialog(item)"
                >
                  <v-icon color="red"> logout </v-icon>
                </v-btn>
              </template>
              <span>Force checkout</span>
            </v-tooltip></span
          >
          <!-- <span v-else-if="item.status == 4"
            >{{ item.status_text
            }}<v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="grey"> logout </v-icon>
                </v-btn>
              </template>
              <span>Force checkout not available</span>
            </v-tooltip></span
          > -->
          <span v-else>{{ item.status_text }}</span>
        </template>
        <!-- <template v-slot:[`item.buildType`]="{ item }">
        {{ item.buildType == 0 || item.buildType == "" ? "-" : item.buildType }}
      </template> -->
        <template v-slot:[`item.checkinDate`]="{ item }">
          {{ item.checkinDate + " " + item.checkinTime }}
        </template>
        <template v-slot:[`item.checkoutDate`]="{ item }">
          {{ item.checkoutDate + " " + item.checkoutTime }}
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="forceCheckoutDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>User Check Out Form</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="text-center pt-3"
          v-if="Object.keys(eventToCheckout).length > 0"
        >
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Order ID
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="eventToCheckout.orderID"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Booking ID (Divrt)
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="eventToCheckout.bid"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Email
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="eventToCheckout.email"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Contact no
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="eventToCheckout.contact"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Vehicle No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="eventToCheckout.vehicleNo"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Checked In
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="
                    eventToCheckout.checkinDate +
                    ' ' +
                    eventToCheckout.checkinTime
                  "
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Check Out
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <divrt-datetime-picker
                  :date-picker-props="datePickerProps"
                  :time-picker-props="timePickerProps"
                  v-model="datetime"
                  label="Select Checkout Time"
                  :menu-props="datetimeMenuProps"
                  :text-field-props="datetimeInputProps"
                ></divrt-datetime-picker>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Reason
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  :items="reasonCodes"
                  v-model="selectedReasonCode"
                  solo
                  return-object
                  item-text="name"
                  item-value="id"
                  flat
                  label="Select reason"
                  hide-details
                ></v-select>
                <!-- <v-textarea
                  solo
                  flat
                  v-model="forceCheckoutEventNotes"
                  name="addNote"
                  label="Add Note Here"
                  rows="1"
                  hide-details
                ></v-textarea> -->
              </v-col>
            </v-row>
            <v-row no-gutters v-if="this.selectedReasonCode.hasOwnProperty('name') && this.selectedReasonCode.name == 'Others'">
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Add notes
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-textarea
                  solo
                  flat
                  v-model="forceCheckoutEventNotes"
                  name="addNote"
                  label="Add Note Here"
                  rows="1"
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4" sm="4" xs="4" md="4" lg="4" xl="4"></v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-radio-group v-model="checkoutOption" column>
                  <v-radio
                    v-for="option in checkoutOptions"
                    :key="option.submit_type"
                    :label="option.option"
                    :value="option.submit_type"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="checkoutOption == '1'">
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Select Exit Lane
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  :items="checkoutExitLanes"
                  v-model="selectedCheckoutLane"
                  solo
                  item-text="option"
                  item-value="lane_id"
                  flat
                  label="Select lane"
                  hide-details
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
          <loading-overlay :loading="loading"></loading-overlay>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
          <v-btn text @click="forceCheckoutDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            @click="openConfirmForceCheckOutDialog()"
            :disabled="isForceCheckoutBtnDisabled"
            
            >Check Out</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialog" max-width="800">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Booking Details Information</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3 black--text">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Customer Name
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.Username || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Email
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.email || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Contact No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.contact || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Vehicle No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.vehicleNo || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Order ID
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.orderID || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Booking ID (Divrt)
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.bid || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Type Of Booking
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.TypeOfBooking || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Status
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.status_text"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Amount ($)
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.price || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Payment Status
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.payment_status || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-show="selectedItem.status_text == 'Checked Out(F)'"
            >
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Force Checked Out By
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  background-color="grey lighten-3"
                  flat
                  :value="selectedItem.forceCheckOut_by || '-'"
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header><span class="font-weight-bold">Other Details</span></v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-2" style="background-color: #f4f6f9">
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        App
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="ltypes[selectedItem.ltype] || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Model
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.model || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        OS Type
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.ostype || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Manufacture
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.manufacture || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        OS Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.osversion || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col> </v-row
                    ><v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        SDK Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.sdk_version || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        App Version
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.appversion || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col
                        cols="4"
                        sm="4"
                        xs="4"
                        md="4"
                        lg="4"
                        xl="4"
                        class="text-right pr-2 align-self-center"
                      >
                        Card Details
                      </v-col>
                      <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                        <v-text-field
                          solo
                          background-color="grey lighten-3"
                          flat
                          :value="selectedItem.CardOnFile || '-'"
                          hide-details
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" class="elevation-0" @click="infoDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmForceCheckoutDialog" persistent max-width="390">
      <v-card>
        <v-card-title class="headline"> Confirmation </v-card-title>
        <v-card-text>{{confirmationDialogMsg}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmForceCheckoutDialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="forceCheckoutBtnLoading"
            @click="forceCheckoutEvent()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import * as API from "@/api/api";
import { mapGetters } from "vuex";
import DivrtDatetimePicker from "@/uiComponents/DateTimePicker.vue";
import { format } from "date-fns";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "LiveReportTable",
  components: { DivrtDatetimePicker },
  props: {
    events_data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    showZoneName: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    ltypes: {
      type: Object,
      default: function () {
        return {};
      },
    },
    type_color_codes: {
      type: Object,
      default: function () {
        return {};
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      reasonCodes: [],
      selectedReasonCode: {},
      infoDialog: false,
      selectedItem: {},
      forceCheckoutEventNotes: "",
      datetime: new Date(),
      datePickerProps: {},
      timePickerProps: {},
      datetimeInputProps: { solo: true, flat: true },
      datetimeMenuProps: {
        transition: "scale-transition",
        "offset-y": true,
        closeOnContentClick: false,
        bottom: true,
      },
      selectedCheckoutLane: null,
      selectedForceCheckOutItem:{},
      confirmationDialogMsg:"",
      confirmForceCheckoutDialog:false,
      checkoutOption: null,
      search: "",
      headers: [
        {
          text: "Zone",
          align: "center",
          value: "zcode",
          width: "130px",
        },
        {
          text: "Order ID",
          align: "center",
          value: "orderID",
          width: "100px",
        },
        {
          text: "Booking ID (Divrt)",
          value: "bid",
          align: "center",
          width: "120px",
        },
        { text: "Email ID", value: "email", align: "center", width: "100px" },
        {
          text: "Contact No",
          value: "contact",
          align: "center",
          width: "100px",
        },
        { text: "Amount   ($)", value: "price", align: "center", width: "80px" },
        {
          text: "Vehicle No",
          value: "vehicleNo",
          align: "center",
          width: "100px",
        },
        {
          text: "Check In Date",
          value: "checkinDate",
          align: "center",
          width: "100px",
        },
        // { text: "Check In Time", value: "checkinTime", align: "center" },
        {
          text: "Check In Mode",
          value: "entry_mode",
          align: "center",
          width: "80px",
        },
        {
          text: "Check Out Date",
          value: "checkoutDate",
          align: "center",
          width: "100px",
        },
        // { text: "Check Out Time", value: "checkoutTime", align: "center" },
        {
          text: "Check Out Mode",
          value: "exit_mode",
          align: "center",
          width: "100px",
        },
        {
          text: "Type",
          value: "TypeOfBooking",
          align: "center",
          width: "150px",
        },
        // { text: "Environment", value: "buildType", align: "center" },
        {
          text: "Status",
          value: "status_text",
          align: "center",
          width: "150px",
        },
        // { text: "Checked Out By", value: "forceCheckOut_by", align: "center", width: "120px"},
      ],
      forceCheckoutDialog: false,
      eventToCheckout: {},
      // dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      // checkoutDateMenu: false,
      // checkoutDate: new Date().toISOString().substr(0, 10),
      // checkoutTime: new Date().getHours() + ":" + new Date().getMinutes(),
      // checkoutTimeMenu: false,
      checkoutExitLanes: [],
      checkoutOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    computedHeaders() {
      return this.showZoneName
        ? this.headers
        : this.headers.filter((a) => !(a.value == "zcode"));
    },
    // computedDateFormatted() {
    //   return this.formatDate(this.checkoutDate);
    // },
    isForceCheckoutBtnDisabled() {
      let isDisabled =
        this.datetime == "" ||
        this.datetime == null ||
        this.checkoutOption == null ||
        this.selectedReasonCode == null ||
        Object.keys(this.selectedReasonCode).length == 0;
      isDisabled =
        this.checkoutOption == "1"
          ? this.selectedCheckoutLane == null || isDisabled
          : isDisabled;
      return isDisabled;
    },
    forceCheckoutBtnLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "FORCE_CHECKOUT_EVENT"
      );
    },
  },
  watch: {
    // checkoutDate(val) {
    //   this.dateFormatted = this.formatDate(val);
    // },
  },
  mounted() {
    // EventBus.$on("filter-live-table", (type) => {
    //   this.search = type;
    // });
  },
  methods: {
    openInfoDialog(item) {
      this.infoDialog = true;
      this.selectedItem = Object.assign({}, item);
    },
    getColor(type) {
      return this.type_color_codes[type]; //Object.keys(this.type_color_codes).find(a => a
    },
    async openConfirmForceCheckOutDialog() {
      this.confirmForceCheckoutDialog = true;
      this.confirmationDialogMsg = this.checkoutOption == 1 ? "A gate opened (by force checkout or otherwise) will remain open until a vehicle passes over the loop detectors. Are you sure you want to open the gates?" : "Are you sure you want to check out user?";
      this.forceCheckoutDialog = false;
    },
    async openForceCheckOutDialog(item){
       this.forceCheckoutDialog = true;
      this.selectedForceCheckOutItem = Object.assign({}, item)
      // this.datetime = new Date();
      // this.datetime.toLocaleString('en-US', {timeZone:item.timezone})
      // this.forceCheckoutDialog = true;
      this.forceCheckoutEventNotes = "";
      // this.datetime = new Date();
      this.datetime = new Date(new Date().toLocaleString('en-US', {timeZone:this.selectedForceCheckOutItem.timezone}))
      // this.datetime
      this.selectedCheckoutLane = null;
      let currentDate = new Date(new Date().toLocaleString('en-US', {timeZone:this.selectedForceCheckOutItem.timezone}));
      let currentTime = new Date().toLocaleTimeString('en-US', {timeZone:this.selectedForceCheckOutItem.timezone});
      this.checkoutOption = null;
      this.eventToCheckout = Object.assign({}, this.eventToCheckout, this.selectedForceCheckOutItem);
      this.datePickerProps = Object.assign({}, this.datePickerProps, {
        min: this.parseDate(this.selectedForceCheckOutItem.checkinDate),
        max: this.parseDate(format(currentDate, "MM/dd/yyyy"))
      });
      this.timePickerProps = Object.assign({}, this.timePickerProps, {
        minTime: this.formatAMPMTo24Time(this.selectedForceCheckOutItem.checkinTime),
        maxTime: this.formatAMPMTo24Time(currentTime),
      });
      try {
        let checkoutOptionsData = this.selectedZone?.zid
          ? await API.forceCheckoutOptions({ zid: this.selectedZone.zid })
          : [];
        this.checkoutOptions = checkoutOptionsData?.data?.checkoutOptions
          ? checkoutOptionsData.data.checkoutOptions
          : [];
        this.checkoutExitLanes = checkoutOptionsData?.data?.data
          ? checkoutOptionsData.data.data
          : [];
        let reasonCodes = this.selectedZone?.zid
          ? await API.getForceCheckoutReasonCodes({
              zid: this.selectedZone.zid,
            })
          : [];
        this.reasonCodes = reasonCodes?.data?.reasons_codes
          ? reasonCodes.data.reasons_codes
          : [];
      } catch (error) {
        console.log(error);
      }
    },
    async forceCheckoutEvent() {
      let notes = this.selectedReasonCode.name == 'Others' ? this.forceCheckoutEventNotes : this.selectedReasonCode.name;
      let postData = {
        zid: this.selectedZone?.zid ? this.selectedZone.zid : "",
        bid: this.eventToCheckout?.id ? this.eventToCheckout.id : "",
        checkout_time: this.datetime
          ? format(this.datetime, "MM/dd/yyyy" + " " + "hh:mm a")
          : "",
        notes: notes,
        submit_type: this.checkoutOption,
        lane_id: this.selectedCheckoutLane,
      };
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "FORCE_CHECKOUT_EVENT",
          pending: true,
        });
        let checkoutResult = this.selectedZone?.zid
          ? await API.fireForceCheckout(postData)
          : [];
        this.forceCheckoutDialog = false;
        this.$notify({
          group: "divrt",
          title:
            checkoutResult?.data?.status && checkoutResult.data.status
              ? "Success"
              : "Error",
          text: checkoutResult?.data?.message
            ? checkoutResult.data.message
            : "",
          type:
            checkoutResult?.data?.status && checkoutResult.data.status
              ? "success"
              : "error",
        });
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "FORCE_CHECKOUT_EVENT",
          pending: false,
        });
        this.confirmForceCheckoutDialog = false;
        EventBus.$emit("force-checkout-event");
      } catch (error) {
        console.log(error);
        this.forceCheckoutDialog = false;
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "FORCE_CHECKOUT_EVENT",
          pending: false,
        });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatAMPMTo24Time(time12h) {
      if (!time12h) return null;
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return hours + ":" + minutes;
    },
  },
};
</script>
<style lang="sass">
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td
  border-right: thin solid rgba(0, 0, 0, 0.12)

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td:first-child, .v-data-table > .v-data-table__wrapper > table > thead > tr > td:first-child .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td:first-child
  border-left: thin solid rgba(0, 0, 0, 0.12)

.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th
  border-bottom: thin solid rgba(0, 0, 0, 0.12)
  border-right: thin solid rgba(0, 0, 0, 0.12)
  border-top: thin solid rgba(0, 0, 0, 0.12)

.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th:first-child
  border-left: thin solid rgba(0, 0, 0, 0.12)
</style>