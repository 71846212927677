<template>
  <v-card height="200px" flat>
    <v-card-title
      ><span class="text-h6">Occupancy Today</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            class="elevation-0"
            @click="resetOccupancyDialog = true && (resetCount = occupied)"
          >
            Reset</v-btn
          >
        </template>
        <span>Reset occupancy</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row no-gutters>
          <v-col class="text-center align-self-end">
            <span class="text-h2">{{ Number(occupied) }}/{{ Number(total_spots) }}</span>
          </v-col>
          <v-col cols="12" class="mt-3">
            <v-progress-linear
              :value="occupancyPercentage"
              color="primary"
              height="40"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <loading-overlay :loading="loading"></loading-overlay> -->
    <v-dialog v-model="resetOccupancyDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Reset Occupancy</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                <span>Count</span>
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  flat
                  v-model="resetCount"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="resetOccupancyDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="resetCheckoutBtnLoading"
            :disabled="resetCount == null || resetCount == ''"
            @click="resetOccupancy()"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import * as API from "@/api/api";
import { mapGetters } from "vuex";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "OccupancyCard",
  props: {
    occupied: {
      type: String,
      default: function () {
        return "";
      },
    },
    total_spots: {
      type: String,
      default: function () {
        return "";
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      resetOccupancyDialog: false,
      resetCount: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    occupancyPercentage() {
      return (Number(this.occupied) / Number(this.total_spots)) * 100;
    },
    resetCheckoutBtnLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "RESET_OCCUPANCY"
      );
    },
  },
  mounted(){
    // console.log(this)
    // console.log(this.getComponent('ManageAccounts'))
  },
  methods: {
//     getComponent(componentName) {
//   let component = null
//   let parent = this.$parent
//   while (parent && !component) {
//     if (parent.$options.name === componentName) {
//       component = parent
//     }
//     parent = parent.$parent
//   }
//   return component
// },
    async resetOccupancy() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "RESET_OCCUPANCY",
          pending: true,
        });
        const resetResult = this.selectedZone?.zid
          ? await API.resetOccupancy({
              zid: this.selectedZone.zid,
              reset_count: this.resetCount,
            })
          : {};
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "RESET_OCCUPANCY",
          pending: false,
        });
        this.$notify({
          group: "divrt",
          title:
            resetResult?.data?.status && resetResult.data.status
              ? "Success"
              : "Error",
          text: resetResult?.data?.message ? resetResult.data.message : "",
          type:
            resetResult?.data?.status && resetResult.data.status
              ? "success"
              : "error",
        });
        this.resetOccupancyDialog = false;
        EventBus.$emit("reset-occupancy");
      } catch (error) {
        console.log(error);
        this.resetOccupancyDialog = false;
      }
    },
  },
};
</script>
