<template>
  <v-card height="200px" flat>
    <v-card-title><span class="text-h6">Visits Today</span></v-card-title>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row no-gutters>
          <v-col cols="6">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{events.pina_checkins}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Pinabox Entries</p>
                  <v-icon color="primary">login</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="6">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{events.pina_checkouts}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Pinabox Exits</p>
                  <v-icon color="secondary">logout</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- <v-col cols="3">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{events.garage_checkins}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Garage Entries</p>
                  <v-icon color="primary">login</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="3">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <spanl cols="3">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{events.garage_checkins}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Garage Entries</p>
                  <v-icon color="primary">login</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="3">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{events.garage_checkouts}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Garage Exits</p>
                  <v-icon color="secondary">logout</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col> class="text-h2">{{events.garage_checkouts}}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Garage Exits</p>
                  <v-icon color="secondary">logout</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <loading-overlay :loading="loading"></loading-overlay> -->
  </v-card>
</template>

<script>
export default {
  name: "VisitsCard",
  props: {
    events: {
      type: Object,
      default: function () {
        return {
          total_spots: null,
          pina_checkins: "",
          pina_checkouts: "",
          garage_checkouts: "",
          garage_checkins: "",
        };
      }
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    }
  },
  computed:{
  }
};
</script>
