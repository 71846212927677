<template>
  <v-card height="200px" flat>
    <v-card-title
      ><span class="text-h6">Revenue ($)</span>
    </v-card-title>
    <v-card-text class="px-0">
      <v-container fluid pa-0>
        <v-row no-gutters>
          <v-col cols="6">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{
              calculateTotalRevenue(events_data, 'Guest')
            }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Guest</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="6">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{
              calculateTotalRevenue(events_data, 'Transient')
            }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">Transient</p>
                  <!-- <v-icon>login</v-icon> -->
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <!-- <v-col cols="6">
            <v-container fluid pa-0 text-center>
              <v-row no-gutters>
                <v-col cols="12">
                  <span class="text-h2">{{
              calculateTotalRevenue(events_data, 'VIP')
            }}</span>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <p class="text-caption mb-0">VIP</p>
                </v-col>
              </v-row>
            </v-container>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <loading-overlay :loading="loading"></loading-overlay> -->
  </v-card>
</template>

<script>
export default {
  name: "TotalRevenue",
  props: {
    events_data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    calculateTotalRevenue(data, type) {
      let sum = data.filter(a => a.TypeOfBooking == type).reduce(
        (accumulator, currentValue) => Number(accumulator) + Number(currentValue.price),
        0
      );
      return sum;//.toFixed(2);
    },
  },
};
</script>
