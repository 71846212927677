<template>
  <v-container fluid>
    <v-row no-gutters>
       <v-col cols="12" sm="12" xs="12" md="6" lg="3" xl="3" class="pa-2">
        <occupancy-card
          :occupied="liveEvents.curr_occupancy"
          :total_spots="liveEvents.total_spots"
          :loading="loading"
        ></occupancy-card>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        md="6"
        lg="3"
        xl="3"
        class="pa-2"
        v-if="liveEvents.hasOwnProperty('parking_event')"
      >
        <visits-card
          :events="liveEvents.parking_event"
          :loading="loading"
        ></visits-card>
      </v-col>
     
      <v-col cols="12" sm="12" xs="12" md="6" lg="3" xl="3" class="pa-2">
        <type-overview :events_data="eventsData" :loading="loading" card-text="Type Overview">
        </type-overview>
      </v-col>
      <v-col cols="12" sm="12" xs="12" md="6" lg="3" xl="3" class="pa-2">
        <total-revenue
          :events_data="eventsData"
          :loading="loading"
        ></total-revenue>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-2">
        <live-report-table
          :loading="loading"
          :show-zone-name="false"
          :ltypes="ltypes"
          :events_data="eventsData"
          :type_color_codes="typeColorCodes"
        ></live-report-table>
      </v-col>
    </v-row>
    <!-- <v-row no-gutters>
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pa-0">
        <parking-overview
          :app_types="ltypes"
          :events_data="eventsData"
          :loading="loading"
        ></parking-overview>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
// @ is an alias to /src
import VisitsCard from "@/components/home/VisitsCard.vue";
import OccupancyCard from "@/components/home/OccupancyCard.vue";
// import ParkingOverview from "@/components/home/ParkingOverview.vue";
import LiveReportTable from "@/components/home/LiveReportTable.vue";
// import AppUsageOverview from "@/components/home/AppUsageOverview.vue";
import TypeOverview from "@/components/home/TypeOverview.vue";
import TotalRevenue from "@/components/home/TotalRevenue.vue";

import { EventBus } from "@/lib/EventBus";
import * as API from "@/api/api";
import { mapGetters } from "vuex";
export default {
  name: "LiveStatsView",
  components: {
    OccupancyCard,
    VisitsCard,
    // ParkingOverview,
    // AppUsageOverview,
    LiveReportTable,
    TotalRevenue,
    TypeOverview,
  },
  data() {
    return {
      liveEvents: {},
      eventsData: [],
      ltypes: {},
      typeColorCodes: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.eventsData = [...vm.eventsData];
    });
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
    loading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"]("LIVE_STATS");
    },
  },
  // watch: {
  //   selectedZone() {
  //     this.fetchLiveStastsData();
  //     // this.fetchLastWeekBookings();
  //   },
  // },
  async created() {},
  beforeMount() {
    this.fetchLiveStastsData();
  },
  async mounted() {
    var self = this;
    setInterval(function(){
        self.fetchLiveStastsData();
    }, 30000);
    EventBus.$on("zone-changed", async () => {
      await this.fetchLiveStastsData();
    });
    EventBus.$on("force-checkout-event", async () => {
      await this.fetchLiveStastsData();
    });
    EventBus.$on("reset-occupancy", async () => {
      await this.fetchLiveStastsData();
    });
  },
  methods: {
    async fetchLiveStastsData() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "LIVE_STATS",
          pending: true,
        });
        const liveStatsData = this.selectedZone?.zid
          ? await API.liveStats({ zid: this.selectedZone.zid })
          : {};
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "LIVE_STATS",
          pending: false,
        });
        // this.selectedZone?.zid ?
        // this.$notify({
        //   group: "divrt",
        //   title: "Success",
        //   text: "Fetched live stats successfully",
        //   type: "success",
        // }) : ""
        this.liveEvents = liveStatsData?.data?.events
          ? Object.assign({}, this.liveEvents, liveStatsData.data.events)
          : Object.assign({}, this.liveEvents);
        this.eventsData = liveStatsData?.data?.data
          ? liveStatsData.data.data
          : [];
        this.ltypes = liveStatsData?.data?.ltypes
          ? liveStatsData.data.ltypes
          : {};
        let btypes = liveStatsData?.data?.bookingtypes
          ? liveStatsData.data.bookingtypes
          : [];
        let mappedCodes =
          liveStatsData?.data?.colorCodes && liveStatsData?.data?.bookingtypes
            ? liveStatsData.data.colorCodes.map((c, i) => ({ [btypes[i]]: c }))
            : [];
        this.typeColorCodes = Object.assign({}, ...mappedCodes);
      } catch (error) {
        console.log(error);
      }
    },
    // async fetchLastWeekBookings() {
    //   let end = new Date().toISOString().split("T")[0];
    //   let start = new Date();
    //   start.setDate(new Date().getDate() - 7);
    //   start = start.toISOString().split("T")[0];
    //   try {
    //     const lastWeekData = this.selectedZone?.zid
    //       ? await API.getBookingsByRange({
    //           zid: this.selectedZone.zid,
    //           start: start,
    //           end: end,
    //         })
    //       : {};
    //     this.weeklyReportData = lastWeekData?.data?.history?.data
    //       ? lastWeekData.data.history.data
    //       : [];
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
};
</script>
